<template>
  <div class="success-cont">
    <img class="icon" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210512141636462.png" alt="">
    <div class="title">订单提交成功</div>
    <div class="desc">恭喜你成为蜜蛋旗舰店店主，请立即激活</div>
    <div class="btns">
      <div @click="handleActive()" class="btn">立即激活</div>
    </div>
    <mi-shop-right :brand-id="brandId" :num="giftNum" />
    <div class="operate">
      <div class="btn" @click="handleRefuse()">残忍拒绝</div>
    </div>
  </div>
</template>

<script>
import MiShopRight from "../Apply/component/MiShopRight";
import { replaceAppToken, deliveryMessage } from "../../utils/appUtil";
import { getCookie } from "../../utils/cookies";
export default {
  components: { MiShopRight },
  data() {
    return {
      brandId: 3,
      giftNum: '1~2', //礼包个数
      isInApp: '0',
      orderId: ""
    }
  },
  created() {
    let { inApp, orderId, token } = this.$route.query;
    if (inApp === '1') this.isInApp = '1';
    this.orderId = orderId;
    if (token) {
      this.isInApp = '1';
      replaceAppToken(token, () => { });
    }
  },
  methods: {
    //立即激活
    handleActive() {
      this.$toast.loading({ message: '加载中...', duration: 0, overlay: true });
      this.get("/OrderGoods/Order/SetIsArgeeUp", {
        orderId: this.orderId,
        state: 1
      }, 2).then(res => {
        this.$toast.clear();
        if (res.code === 1) {
          if (this.isInApp === '1') {
            this.$router.push("/apply/pink?token=" + getCookie("E"))
          } else {
            this.$router.push("/apply/pink")
          }
        }
      }).catch(() => this.$toast.clear())
    },
    //残忍拒绝
    handleRefuse() {
      this.$toast.loading({ message: '加载中...', duration: 0, overlay: true });
      this.get("/OrderGoods/Order/SetIsArgeeUp", {
        orderId: this.orderId,
        state: 0
      }, 2).then(res => {
        this.$toast.clear();
        if (res.code === 1) {
          this.$toast.clear();
          if (this.isInApp === '1') {
            deliveryMessage({ 'action': 'goHome', data: {} })
          } else {
            this.$router.push("/")
          }
        }
      }).catch(() => this.$toast.clear())
    }
  }
}
</script>

<style lang="scss" scoped>
.success-cont {
  padding: 50px 0;

  .icon {
    width: 60px;
  }

  .title {
    color: #666;
    font-size: 18px;
    line-height: 24px;
    padding: 30px 0;
  }

  .desc {
    color: #666;
    font-size: 14px;
  }

  .btns {
    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 10px solid #F8F8F8;

    .btn {
      color: #fff;
      width: 150px;
      height: 36px;
      line-height: 36px;
      border-radius: 18px;
      background-color: #FF98A0;
    }
  }

  /deep/.right-interest {
    &:after {
      border: none;
    }
  }

  .operate {
    padding-top: 30px;
    text-align: center;

    .btn {
      color: #999;
      width: 148px;
      height: 34px;
      display: inline-block;
      line-height: 34px;
      border-radius: 18px;
      border: 1px solid #999999;
    }
  }
}
</style>
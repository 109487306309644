<!-- 蜜店权益 -->
<template>
  <van-cell-group class="right-interest">
    <div class="subtitle">品牌专卖店权益</div>
    <van-cell>
      <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20211221180818108.png" />
      <div class="right-cont">
        <div class="label">品牌专卖店铺</div>
        <div class="ad-txt">
          赠送价值<span>8800</span>元品牌专属小程序蜜蛋店铺
          <template v-if="brandId === 4">
            <br />(跨境电商直邮模式)
          </template>
        </div>
      </div>
    </van-cell>
    <van-cell>
      <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20211221180905412.png" />
      <div class="right-cont">
        <div class="label">带货直播间</div>
        <div class="ad-txt">赠送专属自己的店铺带货微信直播间</div>
      </div>
    </van-cell>
    <van-cell>
      <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20211221180918019.png" />
      <div class="right-cont">
        <div class="label">店主新人礼包</div>
        <div class="ad-txt">订货奖励<span>{{num}}</span>个新人礼包，每个价值<span>200</span>元</div>
      </div>
    </van-cell>
    <van-cell>
      <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20211221180927382.png" />
      <div class="right-cont">
        <div class="label">三重豪礼抽奖</div>
        <div class="ad-txt">店主本人订货订单享受<span>1～3</span>重系统抽奖奖励</div>
      </div>
    </van-cell>
    <van-cell>
      <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20211221180936461.png" />
      <div class="right-cont">
        <div class="label">店铺零售收益</div>
        <div class="ad-txt">尊享个人蜜蛋店铺零售收益、流量扩增</div>
      </div>
    </van-cell>
  </van-cell-group>
</template>

<script>
export default {
  name: 'MiShopRight',
  props: {
    num: {
      type: String,
      default: '1'
    },
    brandId: {
      type: Number,
      default: 3
    }
  },
  data() {
    return {

    }
  },
  created() {

  },
  methods: {

  }
}
</script>
<style lang='scss' scoped>
.right-interest {
  padding: 11px 25px;

  .subtitle {
    font-size: 18px;
    line-height: 40px;
    font-weight: bold;
  }

  .van-cell {
    padding: 11px 0;

    .van-cell__value {
      display: flex;
      align-items: center;

      img {
        width: 35px;
        margin-right: 10px;
      }

      .right-cont {
        width: calc(100% - 45px);

        .label {
          font-size: 14px;
        }

        .ad-txt {
          color: #666666;
          font-size: 12px;

          span {
            color: #c7a8f9;
          }
        }
      }
    }
  }
}
</style>